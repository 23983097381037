import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              DISCOVER <span className="purple"> MY STORY </span>
            </h1>
            <p className="home-about-body">
              I am <span className="purple">Mohamed Massarwa</span>, an entrepreneur at the forefront of the <span className="purple">technology and tradition</span> fusion. With a rich background spanning over a decade, my journey through <span className="purple">agriculture</span>, <span className="purple">engineering</span>, and <span className="purple">real estate</span> has been marked by a relentless pursuit of innovation and sustainable development. 
              <br/><br/>
              My entrepreneurial ventures reflect a deep commitment to excellence and a firm belief in the power of community. By harnessing the strengths of passionate individuals, I strive to drive transformative changes, making a positive impact on our world.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>CONNECT WITH ME</h1>
            <p>
              Join me on my journey and let's make a difference together. <span className="purple">Let's connect!</span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Massarwa"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/MohamedMassarwa"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/mohamed-massarwa-7b81228b/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
