import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import earthsense from "../../Assets/Projects/Earthsense.png";
import agrima from "../../Assets/Projects/Agrima.png";
import agrimaindustries from "../../Assets/Projects/AgrimaIndustries.png";
import masscivil from "../../Assets/Projects/MassCivil.png";
import massarwa from "../../Assets/Projects/Massarwa.png";

function Companies() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Companies</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few companies I've founded or been involved with recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={earthsense}
              isBlog={false}
              title="EarthSense"
              description="A distinguished agriculture dealer, EarthSense brings to market innovative agricultural products and services aimed at enhancing efficiency and productivity for farmers and agricultural enterprises."
              ghLink="#" // Update this if there is a relevant link
              demoLink="https://earthsense.ma/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={agrima}
              isBlog={false}
              title="Agrima Enterprise Holding LTD"
              description="An investment company with a core focus on nurturing and investing in the realms of agriculture technology and real estate. Agrima Enterprise Holding LTD aims to propel the agricultural sector into the future with smart, sustainable investments."
              ghLink="#" // Update this if there is a relevant link
              demoLink="https://agrima.ltd"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={agrimaindustries}
              isBlog={false}
              title="Agrima Industries"
              description="An industrial powerhouse, Agrima Industries specializes in agricultural products and innovations designed to revolutionize farming practices and enhance crop yield and quality."
              ghLink="#" // Update this if there is a relevant link
              demoLink="https://www.agrimaindustries.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={massarwa}
              isBlog={false}
              title="Massarwa"
              description="A leading software development company, Massarwa empowers businesses by addressing their challenges with bespoke software solutions, including design apps and enterprise software, fostering growth and efficiency."
              ghLink="#" // Update this if there is a relevant link
              demoLink="https://massarwa.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={masscivil}
              isBlog={false}
              title="MassCivil"
              description="A premier civil engineering firm, MassCivil is committed to delivering excellence in civil and environmental engineering projects, addressing the industry's complex challenges with innovative and sustainable solutions."
              ghLink="#" // Update this if there is a relevant link
              demoLink="https://www.masscivil.com/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Companies;
